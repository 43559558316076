import { useNavigate } from 'react-router-dom';
import logo from '../common/assets/images/mygrdc/mygrdc_logo.svg';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="shadow-lg navbar">
      <div className="container flex items-center justify-between p-4 mx-auto">
        <img
          src={logo}
          alt="MyGRDC Logo"
          width={120}
          onClick={() => navigate('/')}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Navbar;
