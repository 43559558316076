import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ProfileServiceClient,
  UserProfileInfo,
  UserProfilePayload,
} from '@common/services/api/profileServices/_profileService';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['UserProfile'],
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfileInfo, void>({
      queryFn: async (_, { getState }): Promise<{ data?: UserProfileInfo; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const userProfile = await client.ensureUserProfile(token, subscriptionKey);
          return { data: userProfile.toJSON() };
        } catch (error: any) {
          return {
            error: { status: error.response.status || 500, data: error.response.data || 'Unknown error occurred' },
          };
        }
      },
      providesTags: ['UserProfile'],
    }),
    saveUserProfile: builder.mutation<string, UserProfilePayload>({
      queryFn: async (userProfile, { getState }): Promise<{ data?: string; error?: any }> => {
        const state = getState() as RootState;
        const token = state.authentication.accessToken;

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const updatedUserProfile = await client.saveUserProfile(userProfile, token, subscriptionKey);
          return { data: updatedUserProfile };
        } catch (error: any) {
          console.log('Error saving profile:', error);
          return {
            error: { status: error?.response?.status || 500, data: error?.response?.data || 'Unknown error occurred' },
          };
        }
      },
      invalidatesTags: ['UserProfile'],
    }),
  }),
});

export const { useGetUserProfileQuery, useSaveUserProfileMutation } = usersApi;
