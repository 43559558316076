import clsx from 'clsx';

interface Item {
  refId: string;
  refText: string;
}

interface BoxSelectionProps {
  title: string;
  items: Item[];
  currentSelections: Item[];
  onSelectionChange: (clickedItem: Item, isSelected: boolean) => void;
}

const BoxSelection: React.FC<BoxSelectionProps> = ({
  title,
  items,
  currentSelections,
  onSelectionChange,
}) => {
  const handleItemClick = (item: { refId: string; refText: string }) => {
    const isAlreadySelected = currentSelections.some(
      (selectedItem) => selectedItem.refId === item.refId
    );

    onSelectionChange(item, !isAlreadySelected);
  };

  return (
    <div className="mt-10">
      <div className="mb-2 font-semibold tracking-wide">{title}</div>
      <div className="grid grid-cols-4 gap-5">
        {items.map((item) => {
          const isSelected = currentSelections.some(
            (selectedItem) => selectedItem.refId === item.refId
          );
          return (
            <div
              key={item.refId}
              className={clsx(
                'flex items-center justify-center h-16 border-2 cursor-pointer rounded-xl border-primaryTeal-200',
                isSelected
                  ? 'bg-primary-400 text-white'
                  : 'text-black border-2 border-primaryTeal-200'
              )}
              onClick={() => handleItemClick(item)}
            >
              <div className="font-light">{item.refText}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BoxSelection;
