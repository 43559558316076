import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import { CommonRequest, ContentSearchServiceClient, SearchResponse } from './_contentSearchService';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ContentSearchServiceClient(baseAPIUrl + '/contentsearchservice');

export const latestReleasesApi = createApi({
  reducerPath: 'latestReleasesApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getLatestReleases: builder.query<SearchResponse, CommonRequest>({
      queryFn: async (body, { getState }): Promise<{ data?: SearchResponse; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const latestReleases = await client.getLatestRelease(body, token, subscriptionKey);
          return { data: latestReleases };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetLatestReleasesQuery } = latestReleasesApi;
