import { createSlice } from '@reduxjs/toolkit';

export interface IOnlineState {
  isOnline: boolean;
}

export const initialState: IOnlineState = {
  isOnline: true, // assume connected until proven otherwise
};

const onlineSlice = createSlice({
  name: 'online',
  initialState: initialState,
  reducers: {
    connected: (state) => {
      state.isOnline = true;
    },
    disconnected: (state) => {
      state.isOnline = false;
    },
  },
});

// Export actions and reducer
export const { connected, disconnected } = onlineSlice.actions;
export default onlineSlice.reducer;
