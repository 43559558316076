import RegistrationContainer from './registrationContainer';
import { ReactNode } from 'react';

const Footer = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="relative w-[500px] mx-auto">
      <div className="fixed inset-x-0 bottom-0 flex justify-center">
        <div className="w-screen bg-white shadow-top min-h-32">
          <RegistrationContainer className="h-full ">
            {children}
          </RegistrationContainer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
