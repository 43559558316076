import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface CarouselProps {
  items: { title: string; description: string; icon: string }[];
}
const Carousel: FC<CarouselProps> = ({ items }) => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3100,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        renderBullet: (index: number, className: string) => {
          return `<span class="${className}" style="background-color: #007945;"></span>`;
        },
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper w-full h-[25rem]"
    >
      {items.map((item) => (
        <SwiperSlide key={item.title}>
          <div className="flex flex-col items-center gap-3 p-2">
            <div className="size-64 rounded-full bg-[#E2ECE8] flex justify-center items-center">
              <img src={item.icon} alt={item.title} className="w-full p-12" />
            </div>
            <div className="font-bold">{item.title}</div>
            <div className="font-light text-center">{item.description}</div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
