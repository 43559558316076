import { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateProps {
  currentStep: number;
  persona: {
    oid?: string;
    email?: string;
    nameDetailsInfo?: {
      firstName?: string;
      lastName?: string;
      displayName?: string;
    };
    profileProcessData?: {
      isProfileProcessFinished?: boolean;
    };
    notificationData?: {
      isNotificationEnabled?: boolean;
    };

    userType?: { refId: string; refText: string };
    postCode?: { refId: string; refText: string };
    primaryRegion?: { refId: string; refText: string };
    primarySubregion?: { refId: string; refText: string };
    additionalSubregions?: { refId: string; refText: string }[];
    crops?: { refId: string; refText: string }[];
    topics?: { refId: string; refText: string }[];
    orchestrationId?: string;
  };
}

const initialState: InitialStateProps = {
  currentStep: 0,
  persona: {},
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    incrementStep: (state) => {
      state.currentStep += 1;
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setPersona: (state, action) => {
      state.persona = { ...state.persona, ...action.payload };
    },
    resetPersona: (state) => {
      state.persona = {};
    },
  },
});
export const { incrementStep, decrementStep, setStep, setPersona, resetPersona } = registrationSlice.actions;
export default registrationSlice.reducer;

// Selectors
export const selectCurrentStep = (state: RootState) => state.registration.currentStep;
export const getPersona = (state: RootState) => state.registration.persona;
