import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setToken, selectAccessToken } from '@common/store/authenticationSlice';
import { useAppSelector } from '@common/hooks/useReduxStore';
import { pca } from '../../index';

import Button from '@components/button';
import Carousel from '@components/carousel';
import Container from 'src/components/container';
import TermsAndConditions from './tnC';

import { loginRequest } from '../../msal.config';
import { carouselData, welcomeText } from './data';
import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile';

const Welcome = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = accounts.length > 0 ? accounts[0] : undefined;

  const token = useAppSelector(selectAccessToken);
  const { data: userProfile, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    const fetchToken = async () => {
      if (account) {
        try {
          const tokenResponse = await pca.acquireTokenSilent({
            ...loginRequest,
            account: account,
          });

          if (tokenResponse?.accessToken) {
            dispatch(
              setToken({
                token: tokenResponse.accessToken,
                tokenExpires: tokenResponse.expiresOn?.toISOString() || null,
                objectId: account.homeAccountId || null,
              })
            );
          }
        } catch (error) {
          console.error('Token acquisition failed:', error);
        }
      }
    };

    fetchToken();
  }, [account, dispatch]);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.isProfileProcessFinished) {
        navigate('/dashboard');
      } else if (!userProfile.isProfileProcessFinished) {
        navigate('/registration');
      }
    }
  }, [userProfile, navigate]);

  const handleLogin = () => {
    instance
      .loginRedirect({ ...loginRequest, redirectUri: '/registration' })
      .catch((error) => console.log('Login error:', error));
  };

  const handleCreateAccount = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
        redirectUri: '/registration',
      })
      .catch((error) => console.log('Create account error:', error));
  };

  return (
    <Container>
      <div className="flex flex-col items-center flex-1 mt-16">
        <div className="flex flex-col items-center justify-center">
          <div className="text-5xl font-bold text-center">{welcomeText}</div>
          <div className="w-[18rem] mt-2 flex flex-col font-regular justify-center items-center">
            <Carousel items={carouselData} />
            <div className="flex flex-col font-bold gap-4 mt-16">
              <Button title="Sign in" onClick={handleLogin} />
              <Button title="Create account" onClick={handleCreateAccount} />
            </div>
          </div>
          <div className="px-4 py-10 mt-10">
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Welcome;
