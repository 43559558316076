import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import RegistrationContainer from './registrationContainer';
import Chip from './chip';

type Item = {
    refId: string;
    refText: string;
};

type HorizontalBarProps = {
    items: Item[];
    className?: string;
    onSelectionChange: (selectedItems: Item[]) => void;
};

const HorizontalBar: React.FC<HorizontalBarProps> = ({
    items,
    className,
    onSelectionChange,
}) => {
    const allItem = { refId: '0', refText: 'All' };
    const [selectedItems, setSelectedItems] = useState<Item[]>([allItem]); // Default to "All"

    const handleSelectItem = (item: Item) => {
        setSelectedItems((prev) => {
            if (item.refId === '0') {
                // Selecting "All"
                return [allItem];
            }

            const isItemSelected = prev.some((i) => i.refId === item.refId);

            let newSelectedItems;
            if (isItemSelected) {
                newSelectedItems = prev.filter((i) => i.refId !== item.refId);
            } else {
                newSelectedItems = [
                    ...prev.filter((i) => i.refId !== allItem.refId),
                    item,
                ];
            }

            // Check if all items are selected
            if (newSelectedItems.length === items.length) {
                return [allItem];
            }

            return newSelectedItems.length > 0 ? newSelectedItems : [allItem];
        });
    };

    useEffect(() => {        
        if (selectedItems.length === 1 && selectedItems[0].refId === '0') {
            onSelectionChange(items); // If 'All' is selected, return all items
        } else {
            onSelectionChange(selectedItems);
        }
    }, [selectedItems]); 

    const isSelected = (item: Item) =>
        selectedItems.some((i) => i.refId === item.refId);

    return (
        <div
            className={clsx(
                'w-screen relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] bg-primaryTeal-100 p-2',
                className
            )}
        >
            <RegistrationContainer className="flex flex-wrap gap-3">
                <Chip
                    data={allItem}
                    isSelected={isSelected(allItem)}
                    onClick={() => handleSelectItem(allItem)}
                />
                {items.map((item) => (
                    <Chip
                        key={item.refId}
                        data={item}
                        isSelected={isSelected(item)}
                        onClick={() => handleSelectItem(item)}
                    />
                ))}
            </RegistrationContainer>
        </div>
    );
};

export default HorizontalBar;
