import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PostcodeDetailsInfo, ProfileServiceClient } from '@common/services/api/profileServices/_profileService';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const postCodeApi = createApi({
  reducerPath: 'postCodeApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getPostCodes: builder.query<PostcodeDetailsInfo[], string | null | undefined>({
      queryFn: async (beginsWith = null, { getState }): Promise<{ data?: PostcodeDetailsInfo[]; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const availablePostCodes = await client.getAvailablePostcodes(beginsWith, token, subscriptionKey);
          return { data: availablePostCodes.map((postCode) => postCode.toJSON()) };
        } catch (error: any) {
          return {
            error: { status: error.response?.status || 500, data: error.response?.data || 'Unknown error occurred' },
          };
        }
      },
    }),
  }),
});

export const { useGetPostCodesQuery } = postCodeApi;
