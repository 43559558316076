import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ProfileServiceClient, UserTypeInfo } from '@common/services/api/profileServices/_profileService';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const userTypesApi = createApi({
  reducerPath: 'userTypesApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getUserTypes: builder.query<UserTypeInfo[], void>({
      queryFn: async (_, { getState }): Promise<{ data?: UserTypeInfo[]; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const userTypes = await client.getAvailableUserTypes(token, subscriptionKey);
          return { data: userTypes.map((userType) => userType.toJSON()) };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetUserTypesQuery } = userTypesApi;
