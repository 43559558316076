import React, { useEffect } from 'react';

import podcastIcon from '../../common/assets/images/mygrdc/podcast.svg';
import { useGetLatestVideosPodcastsQuery } from '@common/services/api/searchServices/latestVideosPodcasts';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '@common/store/authenticationSlice';
import { formatDatePublished, formatDuration } from '@/utils/helpers';

const LatestVideosAndPodcasts: React.FC = () => {
  const token = useSelector(selectAccessToken);

  const {
    data: podcastData,
    error,
    isFetching,
    refetch,
  } = useGetLatestVideosPodcastsQuery(
    {
      pageNumber: 1,
      pageSize: 4,
    },
    {
      skip: !token,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (!podcastData || podcastData?.totalCount === 0) {
    return null;
  }

  return (
    <div className="relative mt-16 md:mb-16 lg:mb-[20rem]">
      {/* Background Image Div */}
      <div className="absolute -mt-4 top-0 right-0 w-[66vw] h-[550px]  rounded-tl-[150px] overflow-hidden">
        <img
          src="/Glenn_Carstens-Peters.png"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Main Content Container */}
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4 relative z-10">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-neutral-900">
            Latest podcasts
          </h2>
          {/* <button className="hidden lg:block px-6 py-3 bg-primary-400 text-white text-lg font-bold hover:bg-primary-500 transition-all rounded-md leading-6">
            See all latest podcasts
          </button> */}
        </div>

        {/* Content Section */}
        <div className="relative z-10 mx-auto mt-18 gap-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            {podcastData?.items?.map((item, index) => (
              <div
                key={index}
                className="relative flex bg-white rounded-lg shadow-lg overflow-hidden h-30"
              >
                {/* Image section */}
                <div className="w-1/4 relative">
                  <img
                    src={
                      item.document?.images?.thumbnail
                        ? item.document.images.thumbnail.startsWith('http')
                          ? item.document.images.thumbnail
                          : `https://${item.document.images.thumbnail}`
                        : ''
                    }
                    alt={item.document?.title}
                    className="w-42 h-40 object-cover"
                  />
                  <img
                    src={podcastIcon}
                    alt="Podcast icon"
                    className="absolute bottom-2 left-2 h-10 w-10 rounded-full"
                  />
                </div>
                <div className="p-4 w-2/3 flex flex-col justify-between">
                  <div>
                    <div className="flex items-center mb-2">
                      <span className="text-sm text-neutral-600">
                        {item?.document?.datePublished
                          ? formatDatePublished(item.document.datePublished)
                          : ''}
                        {item?.document?.datePublished &&
                        item.document?.duration
                          ? ' | '
                          : ''}
                        {item.document?.duration
                          ? formatDuration(item.document.duration)
                          : ''}
                      </span>
                    </div>
                    <h5 className="text-lg leading-6 font-bold mb-2 line-clamp-3">
                      <a
                        href={item.document?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {item.document?.title}
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Mobile View Button */}
        {/* <div className="flex justify-center lg:hidden">
          <button className="px-6 py-3 mt-8 bg-primary-400 text-white font-bold rounded-md hover:bg-green-800 text-base leading-6 w-full">
            See all latest podcasts
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default LatestVideosAndPodcasts;
