const TERMS_AND_CONDITIONS = {
  title: 'Terms and Conditions',
  link: 'https://grdc.com.au/policies/disclaimer',
};

const PRIVACY_POLICY = {
  title: 'Privacy Policy',
  link: 'https://grdc.com.au/policies/privacy',
};

const TermsAndConditions = () => {
  return (
    <div className="text-center text-sm font-regular">
      By signing up, you agree to GRDC's{' '}
      <a
        className="text-primary-500 font-bold cursor-pointer"
        href={TERMS_AND_CONDITIONS.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {TERMS_AND_CONDITIONS.title}
      </a>{' '}
      and{' '}
      <a
        className="text-primary-500 font-bold cursor-pointer"
        href={PRIVACY_POLICY.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {PRIVACY_POLICY.title}
      </a>
      .
    </div>
  );
};

export default TermsAndConditions;
