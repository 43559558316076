import Modal from 'react-modal';
import closeIcon from '../common/assets/images/mygrdc/close-outline.svg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNext?: () => void; // Make this optional if not always needed
  children: React.ReactNode; // Accept children to render dynamic content
  title: string; // Add a title prop
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      ariaHideApp={false}
    >
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl w-full h-auto max-h-[80vh] overflow-y-auto">
        {/* Close button using SVG */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <img src={closeIcon} alt="Close" className="w-6 h-6" />
        </button>

        {/* Title - Aligned to the left */}
        <h2 className="text-2xl md:text-2xl text-neutral-800 leading-7 font-bold mb-1">
          {title}
        </h2>

        {/* Content */}
        <div className="py-4 mb-2">{children}</div>
      </div>
    </Modal>
  );
};

export default CustomModal;
