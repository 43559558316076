import Container from '../../components/container';
import notFound from '../../common/assets/images/mygrdc/notFound.svg';
import Button from '../../components/button';
import { useNavigate } from 'react-router-dom';

const NoPermission = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="flex flex-col items-center justify-center flex-1 gap-10 ">
        <div className="text-3xl">No permission to access this page.</div>
        <img src={notFound} alt="Not found" width={200} height={200} />
        <Button title="Go back to homepage" onClick={() => navigate('/')} />
      </div>
    </Container>
  );
};

export default NoPermission;
