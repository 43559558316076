import { useEffect, useMemo, useState } from 'react';
import RegistrationTitle from './registrationTitle';
import RegistrationSubtitle from './registrationSubtitle';
import HorizontalBar from './horizontalBar';
import Spinner from '@components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPersona,
    incrementStep,
    setPersona,
} from '@common/store/registrationSlice';
import BoxSelection from '@components/BoxSelection';
import Footer from './footer';
import Button from '@components/button';
import { useGetTopicsQuery } from '@common/services/api/profileServices/otherTopics';

interface Data {
    refId: string;
    refText: string;
}

const OtherTopics = () => {
    const dispatch = useDispatch();
    const persona = useSelector(getPersona);
    const currentTopics = persona.topics || [];
    const { data: topicTypes, isLoading } = useGetTopicsQuery();
    const [selectedTopicTypes, setSelectedTopicTypes] = useState<Data[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<Data[]>(currentTopics);

    useEffect(() => {
        dispatch(setPersona({ topics: selectedTopics }));
    }, [dispatch, selectedTopics]);

    const allTopicTypes = useMemo(() => {
        const onlyTopicTypes =
            topicTypes?.map((topicType) => ({
                refId: topicType.refId || 'Unknown',
                refText: topicType.refText || 'Unknown',
            })) || []; // Ensure it returns an empty array if topicTypes is undefined
        return onlyTopicTypes;
    }, [topicTypes]);

    const handleTopics = (clickedItem: Data) => {
        const exists = currentTopics.some(
            (topic) => topic.refId === clickedItem.refId
        );

        let updatedTopics;
        if (exists) {
            updatedTopics = currentTopics.filter(
                (topic) => topic.refId !== clickedItem.refId
            );
        } else {
            updatedTopics = [...currentTopics, clickedItem];
        }

        setSelectedTopics(updatedTopics);
    };

    const topicsCount = persona.topics?.length || 0;

    const displayClearBtn = persona.topics && persona.topics?.length !== 0;

    const displayActiveNextBtn =
        persona.topics?.length !== undefined && persona.topics?.length !== 0;

    if (!topicTypes || !allTopicTypes.length || isLoading) {
        return <Spinner />;
    }

    return (
        <div className="mt-6 mb-32">
            <RegistrationTitle title="What other topics are you interested in?" />
            <RegistrationSubtitle subtitle="Select at least one sub-topic to personalise your MyGRDC experience. You can always change this later." />
            <div className="my-4">
                <HorizontalBar
                    items={allTopicTypes}
                    onSelectionChange={(selectedItems) =>
                        setSelectedTopicTypes(selectedItems)
                    }
                />

                {topicTypes
                    .filter((topicType) => {
                        const selectedTopicsIds = selectedTopicTypes.map(
                            (topicType) => topicType.refId
                        );
                        return selectedTopicsIds.includes(topicType.refId ?? '');
                    })
                    .map((topicType) => (
                        <BoxSelection
                            key={topicType.refId}
                            title={topicType.refText || ''}
                            items={(topicType.topics || []).map((topic) => ({
                                refId: topic.refId || '',
                                refText: topic.refText || '',
                            }))}
                            currentSelections={currentTopics}
                            onSelectionChange={handleTopics}
                        />
                    ))}
            </div>
            <Footer>
                <div className="flex justify-end h-full">
                    <div className="flex items-center w-full gap-5 ">
                        <div className="flex-1 text-xl font-semibold">
                            {`${topicsCount} topic${topicsCount === 1 ? '' : 's'} selected`}
                        </div>
                        {displayClearBtn ? (
                            <Button
                                title="Clear"
                                type="outline"
                                onClick={() => dispatch(setPersona({ topics: undefined }))}
                            />
                        ) : (
                            <Button title="Clear" type="disabled" />
                        )}
                        {displayActiveNextBtn ? (
                            <Button
                                title="Next"
                                type="filled"
                                onClick={() => dispatch(incrementStep())}
                            />
                        ) : (
                            <Button title="Next" type="disabled" />
                        )}
                    </div>
                </div>
            </Footer>
        </div>
    );
};

export default OtherTopics;
