import clsx from 'clsx';
import { FC } from 'react';

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className }) => {
  return (
    <div
      className={clsx('flex items-center justify-center h-[300px]', className)}
    >
      <div className="w-16 h-16 border-4 border-double rounded-full border-primary-400 animate-pulse"></div>
    </div>
  );
};

export default Spinner;
