import React, { useEffect, useMemo, useState } from 'react';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Container from '../../components/container';
import latestReleasesHomePageImage from '../../common/assets/images/mygrdc/latest-releases.png';
import { useGetLatestReleasesQuery } from '@common/services/api/searchServices/latestReleases';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '@common/store/authenticationSlice';
import Pagination from '@components/pagination';
import { formatDateRegion } from '@/utils/helpers';
import chevronUp from '../../common/assets/images/mygrdc/chevron-up.svg';
import chevronDown from '../../common/assets/images/mygrdc/chevron-down.svg';
import Footer from './footer';

const AllLatestReleasesPage: React.FC = () => {
  const token = useSelector(selectAccessToken);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState('Relevance');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const itemsPerPage = 10;

  const sortingOptions = [
    { label: 'Relevance', value: 0 },
    { label: 'Newest to oldest', value: 2 },
    { label: 'Oldest to newest', value: 1 },
  ];

  const currentSortOption = useMemo(() => {
    return sortingOptions.find((option) => option.label === selectedOption)
      ?.value;
  }, [selectedOption]);

  const {
    data: releases,
    error,
    isFetching,
    refetch,
  } = useGetLatestReleasesQuery(
    {
      sortBy: currentSortOption,
      pageNumber: currentPage + 1,
      pageSize: itemsPerPage,
    },
    {
      skip: !token,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const currentItems = releases?.items ?? [];

  const totalItems = releases?.totalCount || 0;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const startItem = currentPage * itemsPerPage + 1;
  const endItem = Math.min((currentPage + 1) * itemsPerPage, totalItems);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (!releases || releases.totalCount === 0) {
    return null;
  }

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option.label);
    setIsDropdownVisible(false);
  };

  const handlePageClick = (selectedPage: number) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
    }
  };

  return (
    <>
      <Container navbar={<NavbarWithMenu />}>
        <div className="bg-white">
          {/* Image Section with rounded right side from middle */}
          <div className="relative w-[93%] h-72 rounded-br-[160px]  overflow-hidden">
            <img
              src={latestReleasesHomePageImage}
              alt="Latest releases banner"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="text-left mt-8 px-16 md:px-68 lg:px-68 xl:px-72">
            <h2 className="text-4xl font-bold">Latest releases</h2>
            <p className="mt-4 font-regular">
              The results below are recently published or updated publications,
              GrowNotes, and update papers, curated by your profile settings.
            </p>
          </div>

          <div className="mt-8 px-16 md:px-68 lg:px-68 xl:px-72">
            <div className="flex justify-between items-center mb-4">
              <span className="font-regular">
                {`${startItem} - ${endItem} of ${totalItems} for`} {''}
                <span className="font-boldItalic">latest releases</span>
              </span>

              <div
                className="relative"
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => setIsDropdownVisible(false)}
              >
                <div className="text-device-green  font-bold py-2 px-3 cursor-pointer flex items-center">
                  {selectedOption}
                  <img
                    src={isDropdownVisible ? chevronUp : chevronDown}
                    alt="Toggle Dropdown"
                    className="ml-2 w-4 h-4"
                  />
                </div>

                {isDropdownVisible && (
                  <div
                    className="absolute z-10 w-44 bg-shades-white rounded-lg shadow-lg right-0"
                    onMouseEnter={() => setIsDropdownVisible(true)}
                    onMouseLeave={() => setIsDropdownVisible(false)}
                  >
                    {sortingOptions.map((option) => (
                      <div
                        key={option.value}
                        className="px-4 py-2 cursor-pointer hover:bg-primaryTeal-100 font-regular"
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* List of Releases */}
            {(currentItems?.length ?? 0) > 0 && (
              <div className="space-y-6">
                {currentItems?.map((release, index) => (
                  <div
                    key={index}
                    className={`pb-4 ${
                      index !== currentItems.length - 1 ? 'border-b' : ''
                    }`}
                  >
                    <div className="font-regular text-neutral-600 text-sm mb-1">
                      {formatDateRegion(
                        release?.document?.datePublished,
                        release?.document?.region
                      )}
                    </div>
                    <h5 className="mt-2 font-bold text-lg leading-6 text-left overflow-hidden text-ellipsis line-clamp-4">
                      <a
                        href={release.document?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {release.document?.title}
                      </a>
                    </h5>

                    <p className="mt-1 font-regular text-neutral-600 overflow-hidden text-ellipsis line-clamp-2">
                      {release?.document?.summary}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {pageCount > 1 && (
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default AllLatestReleasesPage;
