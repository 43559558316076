import search from '@common/assets/images/mygrdc/search.svg';
import save from '@common/assets/images/mygrdc/save.svg';
import share from '@common/assets/images/mygrdc/share.svg';

export const welcomeText = 'Welcome to MyGRDC'

export const carouselData = [
    {
      title: 'Search',
      description:
        'Search for latest research and resources, tailored to your needs.',
      icon: search,
    },
    {
      title: 'Save',
      description:
        'Save your favourite resources into personalised collections.',
      icon: save,
    },
    {
      title: 'Share',
      description: 'Share your saved resources and collections with others.',
      icon: share,
    },
  ];