/**
 * The file contains application settings pulled from dotenv files.
 * See: https://github.com/bkeepers/dotenv
 */
const APP_PROPERTIES = {
  appGreeting: process.env.REACT_APP_GREETING ?? '',
  msalClientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
  msalAuthorityUrl: process.env.REACT_APP_MSAL_AUTHORITY_URL ?? '',
};

export default APP_PROPERTIES;
