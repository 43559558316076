import React, { ReactNode } from 'react';

const RegistrationSubtitle = ({ subtitle }: { subtitle: ReactNode }) => {
  return (
    <div className="mt-2">
      <div className="font-regular tracking-wide text-md text-neutral-800">
        {subtitle}
      </div>
    </div>
  );
};

export default RegistrationSubtitle;
