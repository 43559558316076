import RegistrationTitle from './registrationTitle';
import feedImage from '@common/assets/images/mygrdc/feed.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@common/hooks/useReduxStore';
import { getPersona } from '@common/store/registrationSlice';
import { useSaveUserProfileMutation } from '@common/services/api/profileServices/userProfile';
import {
  NotificationData,
  ProfileProcessData,
  UserProfilePayload,
} from '@common/services/api/profileServices/_profileService';

const PersonalisingFeed = () => {
  const navigate = useNavigate();
  const persona = useAppSelector(getPersona);
  const [saveUserProfile] = useSaveUserProfileMutation();

  useEffect(() => {
    console.log('Available crops in Redux store:', persona.crops);

    const userProfilePayload = new UserProfilePayload({
      postcode: persona.postCode?.refText,
      primaryRegionId: persona.primaryRegion?.refId,
      primarySubregionId: persona.primarySubregion?.refId,
      crops: persona.crops?.map((crop) => crop.refId),
      topics: persona.topics?.map((topic) => topic.refId),
      userTypeId: persona.userType?.refId,
      additionalSubregions: persona.additionalSubregions?.map(
        (sub) => sub.refId
      ),
      profileProcessData: new ProfileProcessData({
        isProfileProcessFinished:
          !persona.profileProcessData?.isProfileProcessFinished,
      }),
      notificationData: new NotificationData({
        isNotificationEnabled: !persona.notificationData?.isNotificationEnabled,
      }),
    });

    console.log('User Profile Payload:', JSON.stringify(userProfilePayload));

    const saveProfile = async () => {
      try {
        await saveUserProfile(userProfilePayload).unwrap();
        console.log('Profile saved successfully!');
      } catch (error) {
        console.error('Error saving profile:', error);
      }
    };

    saveProfile();

    setTimeout(() => {
      navigate('/dashboard', { replace: true });
    }, 4000);
  }, [navigate, persona, saveUserProfile]);

  return (
    <div className="flex flex-col items-center justify-center flex-1 gap-8">
      <RegistrationTitle title="Personalising your dashboard..." />
      <img src={feedImage} alt="Feed" width={200} className="animate-pulse" />
    </div>
  );
};

export default PersonalisingFeed;
