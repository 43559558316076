import React, { useEffect } from 'react';

import rightGreen from '../../common/assets/images/mygrdc/chevron-right-green.svg';
import { useGetLatestReleasesQuery } from '@common/services/api/searchServices/latestReleases';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '@common/store/authenticationSlice';
import { useNavigate } from 'react-router-dom';
import { formatDateRegion } from '@/utils/helpers';

const LatestReleases: React.FC = () => {
  const token = useSelector(selectAccessToken);
  const navigate = useNavigate();

  const {
    data: releases,
    error,
    isFetching,
    refetch,
  } = useGetLatestReleasesQuery(
    {
      pageNumber: 1,
      pageSize: 3,
    },
    {
      skip: !token,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (!releases || releases?.totalCount === 0) {
    return null;
  }

  return (
    <div className="bg-white w-full pt-16 pb-24">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-neutral-900">
            Latest releases
          </h2>
          <button
            onClick={() => navigate('/allLatestReleases')}
            className="hidden lg:block px-6 py-3 bg-primary-400 text-white text-lg font-bold rounded-md hover:bg-primary-500 transition-all"
          >
            See all latest releases
          </button>
        </div>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 pb-8 sm:pb-12">
          {releases?.items?.map((release) => (
            <div
              key={release.document?.id}
              className="flex flex-col justify-between p-4 border border-primaryTeal-200 rounded-lg h-full"
            >
              <div>
                <div className="font-semibold text-neutral-600 text-sm mb-1">
                  {formatDateRegion(
                    release?.document?.datePublished,
                    release?.document?.region
                  )}
                </div>
                <h5 className="mt-2 font-bold text-lg leading-6 text-left overflow-hidden text-ellipsis line-clamp-4">
                  {release.document?.title}
                </h5>
              </div>
              {release.document?.url && (
                <a
                  href={release.document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-400 font-bold mt-2 flex items-center space-x-1"
                >
                  <span>Read more</span>
                  <img
                    src={rightGreen}
                    alt="Chevron Right"
                    className="w-6 h-6"
                  />
                </a>
              )}
            </div>
          ))}
        </div>

        {/* Button Section for smaller screens */}
        <div className="flex justify-center lg:hidden">
          <button
            onClick={() => navigate('/allLatestReleases')}
            className="px-6 py-3 bg-primary-400 text-white font-bold rounded-md hover:bg-green-800 w-full"
          >
            See all latest releases
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestReleases;
