import './index.css';

import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { msalConfig } from './msal.config';
import reportWebVitals from './reportWebVitals';
import { Provider, useDispatch } from 'react-redux';
import { store } from '../src/common/store/store'; // Ensure this is the correct path to your store setup
import RegistrationTitle from '../src/pages/registration/registrationTitle';
import feedImage from '@common/assets/images/mygrdc/feed.svg';
import { ID_TOKEN } from '@azure/msal-common/dist/constants/AADServerParamKeys';
import { setToken } from '@common/store/authenticationSlice';
import { useAppDispatch } from '@common/hooks/useReduxStore';

/**
 * MSAL should be instantiated outside of the component tree to prevent it
 * from being re-instantiated on re-renders.
 */
export const pca = new PublicClientApplication(msalConfig);
export const msalInitializedPromise = pca.initialize(); // Create a promise for initialization

const Root = () => {
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleMsalInitialization = async () => {
      try {
        await msalInitializedPromise; // Wait for the MSAL initialization

        // Handle redirect authentication flow (first login)
        const response = await pca.handleRedirectPromise();
        if (response && response.account) {
          const tokenPayLoad = {
            token: response.accessToken,
            tokenExpires: response.idToken,
            objectId: response.refreshOn,
          };

          dispatch(setToken(tokenPayLoad));
        }

        // Fetch token silently on refresh
        const accounts = pca.getAllAccounts();
        if (accounts.length > 0) {
          const silentResponse = await pca.acquireTokenSilent({
            account: accounts[0], // Use the first account (if multiple accounts are supported)
            scopes: ['user.read', 'email'], // Add your scopes here
          });

          const tokenPayLoad = {
            token: silentResponse.accessToken,
            tokenExpires: silentResponse.idToken,
            objectId: silentResponse.refreshOn,
          };

          dispatch(setToken(tokenPayLoad)); // Dispatch the token to the Redux store
        }

        setIsMsalInitialized(true); // Set the state to true after initialization
        console.log('MSAL initialized successfully.');
      } catch (error) {
        console.error('MSAL initialization failed:', error);
      }
    };

    handleMsalInitialization();
  }, []);

  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <Root />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();
