import { useEffect, useState } from 'react';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Container from '../../components/container';
import searchLogo from '../../common/assets/images/mygrdc/searchMenuGray.svg';
import closeIcon from '../../common/assets/images/mygrdc/close-filled.svg';
import Footer from './footer';
import searchLandingImage from '../../common/assets/images/mygrdc/searchLandingPage.jpg';
import errorIcon from '../../common/assets/images/mygrdc/error.svg';
import chevronUp from '../../common/assets/images/mygrdc/chevron-up.svg';
import chevronDown from '../../common/assets/images/mygrdc/chevron-down.svg';
import podcastIcon from '../../common/assets/images/mygrdc/podcast.svg';
import notFoundIcon from '../../common/assets/images/mygrdc/notFound.svg';

import Pagination from '../../components/pagination';
import {
  formatDatePublished,
  formatEventDateRange,
  formatHighlightedText,
  joinRegions,
  validateSearchInput,
} from '../../utils/helpers';
import { useSearchContentQuery } from '@common/services/api/searchServices/searchContent';
import { useLocation } from 'react-router-dom';

const Search = () => {
  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useState(''); // State for actual query submission
  const [errorMessage, setErrrorMessage] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Relevance');
  const [shouldFetch, setShouldFetch] = useState(false);

  const location = useLocation();
  const dashboardQueryParams = new URLSearchParams(location.search);
  const dashboardQueryText = dashboardQueryParams.get('query') || '';

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const sortingOptions = [
    { label: 'Relevance', value: 0 },
    { label: 'Newest to oldest', value: 2 },
    { label: 'Oldest to newest', value: 1 },
  ];

  const currentSortOption = sortingOptions.find(
    (option) => option.label === selectedOption
  )?.value;

  const {
    data: searchResultsData,
    error,
    isFetching,
    refetch,
  } = useSearchContentQuery(
    {
      query: queryText,
      sortBy: currentSortOption,
      pageNumber: currentPage + 1,
      pageSize: itemsPerPage,
    },
    {
      skip: !shouldFetch,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (dashboardQueryText) {
      setShowResults(true);
      setCurrentPage(0);
      setSearchText(dashboardQueryText);
      setQueryText(dashboardQueryText);
      setShouldFetch(true);

      const newUrl = '/search';
      window.history.replaceState(null, '', newUrl);
    }
  }, [dashboardQueryText]);

  const currentItems = searchResultsData?.items ?? [];

  const pageCount = Math.ceil(
    (searchResultsData?.totalCount || 0) / itemsPerPage
  );

  const handleClearSearch = () => {
    setSearchText('');
    setErrrorMessage('');
    setShowResults(false);
    setShouldFetch(false);
    setQueryText('');
    setCurrentPage(0);
  };

  const handleSubmitSearch = () => {
    setShowResults(true);
    setCurrentPage(0);
    setQueryText(searchText);
    setShouldFetch(true);
  };

  const handleSearchInputChange = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    const validationError = validateSearchInput(text);
    setErrrorMessage(validationError);
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option.label);
    setIsDropdownVisible(false);
    setShouldFetch(true);
    setShowResults(true);
  };

  const handlePageClick = (selectedPage: number) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
      setShouldFetch(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText.trim() && !errorMessage) {
      handleSubmitSearch();
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Container navbar={<NavbarWithMenu />}>
        <div
          className={`flex flex-col  ${
            !showResults ? 'flex-auto bg-primaryTeal-100' : ''
          }`}
        >
          <div className="relative flex flex-col h-80">
            {/* Adjusted height for background */}
            <div className="bg-tint-light h-4/5 md:h-2/3 top-0 absolute w-full">
              {/* Adjusted to align with the middle of the input field */}
              <div className="flex-1 mt-4 py-6 px-4 md:py-16 md:px-24 lg:px-56">
                {/* Parent container to align heading and search */}
                <div className="w-full mx-auto px-16">
                  {/* Heading */}
                  {!showResults ? (
                    <div className="font-bold text-4xl leading-9 text-shades-white text-center tracking-wide md:text-5xl md:leading-10 md:text-left">
                      Search
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {!showResults ? (
              <div className="absolute  pl-10 md:right-0 w-full bottom-0 md:w-1/2  h-1/2 md:h-full">
                <img
                  src={searchLandingImage}
                  alt="Grain growers"
                  className="h-full  object-cover w-full rounded-bl-[80px] md:rounded-bl-[150px]"
                />
              </div>
            ) : (
              ''
            )}

            {/* Search Input and Button */}
            <div
              className={`flex items-center bg-shades-white rounded-xl shadow-bottom
            px-4 py-3 h-20 md:h-20 relative transition duration-300 ease-in-out focus-within:border-tint-dark
            border-transparent border-2 box-border font-regular w-96 md:w-2/3 mx-auto my-auto mt-24 min-h-20 ${
              !showResults ? 'md:mt-44' : 'md:mt-16'
            }`}
            >
              {/* Search Icon and Input Field */}
              <div className="relative flex items-center flex-grow h-14 md:h-full bg-shades-white rounded-md">
                {/* Search Icon */}
                <span className="absolute inset-y-0 left-0 flex items-center pl-4 md:pl-4 sm:pl-2">
                  <img src={searchLogo} alt="Search" width={24} height={24} />
                </span>

                {/* search text input Field */}
                <input
                  type="text"
                  placeholder="Search for resources, trials, events & more"
                  value={searchText}
                  onChange={handleSearchInputChange}
                  onKeyDown={handleKeyDown}
                  className="w-full pl-12 h-full rounded-md placeholder-neutral-600 outline-none
                placeholder:text-[16px] placeholder:leading-6 placeholder:text-left placeholder:font-regular"
                />

                {/* Close Button (appears when typing) */}
                {searchText && (
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 p-1"
                    onClick={handleClearSearch}
                  >
                    <img src={closeIcon} alt="Clear" />
                  </button>
                )}
              </div>
              <button
                onClick={handleSubmitSearch}
                disabled={!searchText.trim() || !!errorMessage}
                className={`block  h-12 md:h-full px-4 text-shades-white rounded-md bg-device-green hover:bg-primary-400 
                  ${searchText ? 'w-28' : 'w-44'}`}
              >
                Search
              </button>
            </div>
            {errorMessage && (
              <div className="w-full px-6 sm:px-2 md:px-72 z-20">
                <div
                  className={`flex items-center text-semanticsError-500 font-regular text-sm mt-2  z-20 
                    ${searchText ? 'mb-32' : 'mb-20 md:mb-14'}
                 bg-red-100 px-4 py-2 rounded-lg w-full min-h-14 max-w-lg`}
                >
                  <img
                    src={errorIcon}
                    alt="Error icon"
                    className="-mt-4 lg:-mt-3 lg:mb-1 mr-2 h-4 w-4 rounded-full"
                  />
                  <span className="text-left">{errorMessage}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full md:w-2/3 mx-auto bg-shades-white flex flex-col px-4 sm:px-6">
          <div className="relative">
            <div className="flex justify-between items-center mb-4 -mt-16">
              {showResults && !isFetching && (
                <h2 className="text-2xl font-bold">
                  {searchResultsData?.totalCount &&
                  searchResultsData.totalCount > 0
                    ? `${searchResultsData.totalCount} results`
                    : 'No results found'}
                </h2>
              )}

              {showResults && !isFetching && (
                <div
                  className="relative"
                  onMouseEnter={() => setIsDropdownVisible(true)}
                  onMouseLeave={() => setIsDropdownVisible(false)}
                >
                  <div className="text-device-green  font-bold py-2 px-3 cursor-pointer flex items-center">
                    {selectedOption}
                    <img
                      src={isDropdownVisible ? chevronUp : chevronDown}
                      alt="Toggle Dropdown"
                      className="ml-2 w-4 h-4"
                    />
                  </div>

                  {isDropdownVisible && (
                    <div
                      className="absolute z-10 w-44 bg-shades-white rounded-lg shadow-lg right-0"
                      onMouseEnter={() => setIsDropdownVisible(true)}
                      onMouseLeave={() => setIsDropdownVisible(false)}
                    >
                      {sortingOptions.map((option) => (
                        <div
                          key={option.value}
                          className="px-4 py-2 cursor-pointer hover:bg-primaryTeal-100 font-regular"
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* displaying results */}
          {showResults && !isFetching ? (
            searchResultsData?.totalCount &&
            searchResultsData.totalCount > 0 ? (
              <div className="font-regular mb-6 bg-neutral-100 text-center py-2 rounded">
                Showing results for{' '}
                <span className="font-boldItalic">{queryText}</span>
              </div>
            ) : (
              <div className="font-regular mb-6 bg-neutral-100 text-center py-2 rounded">
                0 search results for{' '}
                <span className="font-boldItalic">{searchText}</span>
              </div>
            )
          ) : null}

          {showResults && !isFetching && (currentItems?.length ?? 0) > 0 && (
            <div className="space-y-6">
              {currentItems?.map((result: any, index: any) => (
                <div
                  key={result?.document?.id}
                  className={`pb-4 flex items-start space-x-4 ${
                    index !== currentItems.length - 1 ? 'border-b' : ''
                  }`}
                >
                  {result?.document?.images?.thumbnail &&
                    result?.document?.duration &&
                    result?.document?.type === 'podcast' && (
                      <div className="relative w-20 h-20 flex-shrink-0 sm:w-20 sm:h-20">
                        <img
                          src={result?.document?.images?.thumbnail}
                          alt={result?.document?.title}
                          className="w-full h-full object-cover rounded-md"
                        />
                        <img
                          src={podcastIcon}
                          alt="Podcast icon"
                          className="absolute bottom-2 left-2 h-7 w-7 rounded-full"
                        />
                      </div>
                    )}

                  <div className="flex-1 w-36">
                    <p className="text-sm font-regular truncate">
                      {result?.document?.type === 'event'
                        ? result?.document?.eventStartDate &&
                          result?.document?.eventEndDate
                          ? formatEventDateRange(
                              result?.document?.eventStartDate,
                              result?.document?.eventEndDate
                            )
                          : ''
                        : result?.document?.calculatedDate
                        ? formatDatePublished(result?.document?.calculatedDate)
                        : ''}
                      {(result?.document?.calculatedDate ||
                        (result?.document?.eventStartDate &&
                          result?.document?.eventEndDate)) &&
                      result?.document?.region
                        ? ' | '
                        : ''}
                      {result?.document?.region
                        ? joinRegions(result?.document?.region)
                        : ''}
                    </p>
                    <h3 className="text-lg font-semiBold mt-1 overflow-hidden">
                      <a
                        href={result?.document?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {result?.highlights?.title[0]
                          ? formatHighlightedText(result.highlights.title[0])
                          : result?.document?.title}
                      </a>
                    </h3>
                    <p className="font-regular text-neutral-600 overflow-hidden text-ellipsis line-clamp-2">
                      {result?.document?.summary}
                    </p>
                  </div>
                </div>
              ))}
              {pageCount > 1 && (
                <Pagination
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                />
              )}
            </div>
          )}
        </div>

        {/* displaying error message */}
        {showResults &&
          !isFetching &&
          (searchResultsData?.items?.length ?? 0) === 0 && (
            <div className="flex flex-col items-center text-center p-8">
              <div className="flex items-center justify-center mb-4 rounded-full bg-primaryTeal-100 w-28 h-28">
                <img
                  src={notFoundIcon}
                  alt="No results found"
                  className="w-16 h-16"
                />
              </div>

              <p className="text-gray-500 mt-4 max-w-96 leading-relaxed font-regular">
                Sorry we couldn’t find anything that matches your search. Try
                broadening your search phrase, rewording your search, or
                clearing any filters.
              </p>
            </div>
          )}
      </Container>
      <Footer />
    </div>
  );
};

export default Search;
