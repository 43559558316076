import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Outlet } from 'react-router-dom';
import Welcome from '../pages/welcome/welcome';

const ProtectedRoute: React.FC = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Welcome />
      </UnauthenticatedTemplate>
    </>
  );
};

export default ProtectedRoute;
