import clsx from 'clsx';

interface Item {
    refId: string; 
    refText: string;
}

const Chip = ({
    data,
    isSelected,
    onClick,
}: {
    data: Item;
    isSelected: boolean;
    type?: string;
    onClick: (data: Item) => void;
}) => {
    return (
        <div
            className={clsx(
                'px-4 py-1 text-sm rounded-3xl cursor-pointer',
                isSelected
                    ? 'bg-primary-400 text-white'
                    : 'border border-primaryTeal-200 bg-white'
            )}
            onClick={() => onClick(data)}
        >
            <div className="font-light">{data.refText}</div>
        </div>
    );
};

export default Chip;
