import RegistrationTitle from './registrationTitle';
import RegistrationSubtitle from './registrationSubtitle';
import clsx from 'clsx';
import HorizontalBar from './horizontalBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPersona,
  incrementStep,
  setPersona,
} from '@common/store/registrationSlice';
import Spinner from '@components/spinner';
import { useMemo, useState } from 'react';
import Footer from './footer';
import Button from '@components/button';
import { useGetRegionsQuery } from '@common/services/api/profileServices/regions';

interface Data {
  refId: string;
  refText: string;
}

const AdditionalSubRegions = () => {
  const dispatch = useDispatch();
  const persona = useSelector(getPersona);
  const { data: regions, isLoading } = useGetRegionsQuery();
  const [selectedRegions, setSelectedRegions] = useState<Data[]>([]);

  const allRegions = useMemo(() => {
    return (
      regions?.map((region) => ({
        refId: region.refId || 'Unknown',
        refText: region.refText || 'Unknown',
      })) || []
    ); // Return an empty array if regions is undefined
  }, [regions]);

  const displayClearBtn =
    persona.additionalSubregions && persona.additionalSubregions.length !== 0;

  if (!regions || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="mt-6 mb-32 ">
        <RegistrationTitle title="Select additional sub-regions" />
        <RegistrationSubtitle
          subtitle={
            <>
              Choose up to 10 more sub-regions to personalise your MyGRDC
              experience.
              <br />
              You can change these later.
            </>
          }
        />

        <HorizontalBar
          items={allRegions}
          onSelectionChange={(selectedItems) => {
            // Transform selected items to Data type
            const transformedItems: Data[] = selectedItems.map((item) => ({
              refId: item.refId,
              refText: item.refText,
            }));
            setSelectedRegions(transformedItems);
          }}
        />

        <div className="flex flex-col gap-12 mt-6">
          {regions
            .filter((region) => {
              const selectedRegionsIds = selectedRegions.map(
                (region) => region.refId
              );
              return selectedRegionsIds.includes(region.refId ?? '');
            })
            .map((region) => (
              <div key={region.refId} className="flex flex-col gap-2">
                <div className="font-semibold">{region.refText}</div>
                <div className="flex flex-col gap-2">
                  {region.subRegions?.map((subRegion) => (
                    <Subregions
                      key={subRegion.refId}
                      data={{
                        refId: subRegion.refId || 'Unknown',
                        refText: subRegion.refText || 'Unknown',
                      }}
                    />
                  )) || null}{' '}
                  {/* Handle undefined subRegions */}
                </div>
              </div>
            ))}
        </div>
        <Footer>
          <div className="flex justify-end h-full">
            <div className="flex items-center w-full gap-5 ">
              <div className="flex-1 text-xl font-semibold ">
                {persona.additionalSubregions?.length || 0} of 10 selected
              </div>
              {displayClearBtn ? (
                <Button
                  title="Clear"
                  type="outline"
                  onClick={() =>
                    dispatch(setPersona({ additionalSubregions: undefined }))
                  }
                />
              ) : (
                <Button title="Clear" type="disabled" />
              )}
              <Button
                title="Next"
                type="filled"
                onClick={() => dispatch(incrementStep())}
              />
            </div>
          </div>
        </Footer>
      </div>
    </>
  );
};

export default AdditionalSubRegions;

const Subregions = ({
  data,
}: {
  data: { refId: string; refText: string };
  isPrimary?: boolean;
}) => {
  const dispatch = useDispatch();
  const persona = useSelector(getPersona);
  const isSelected = !!persona.additionalSubregions?.some(
    (subRegion) => subRegion.refId === data.refId
  );
  const isPrimary = persona.primarySubregion?.refText === data.refText;

  const handleSelectSubRegion = (data: { refId: string; refText: string }) => {
    if (isPrimary) return; // Prevent changes to the primary subregion

    const additionalSubregions = persona.additionalSubregions || [];

    if (
      additionalSubregions.length < 10 &&
      !additionalSubregions.some((subRegion) => subRegion.refId === data.refId)
    ) {
      dispatch(
        setPersona({
          additionalSubregions: [...additionalSubregions, data],
        })
      );
    } else {
      dispatch(
        setPersona({
          additionalSubregions: additionalSubregions.filter(
            (subRegion) => subRegion.refId !== data.refId
          ),
        })
      );
    }
  };

  return (
    <div
      className={clsx(
        'flex items-center justify-between w-full px-4 py-2 border-2 rounded-lg',
        isSelected
          ? 'border-primary-400 bg-primaryTeal-100'
          : 'border-primaryTeal-200 bg-white',
        isPrimary ? '' : 'cursor-pointer'
      )}
      onClick={() => handleSelectSubRegion(data)}
    >
      <span className="text-sm font-light">{data.refText}</span>
      <div className="relative flex items-center justify-center">
        {!isPrimary ? (
          <>
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => handleSelectSubRegion(data)}
              className={clsx(
                'w-4 h-4 text-white rounded-sm appearance-none cursor-pointer',
                isSelected ? 'bg-green-700' : 'border-2  border-primary-400'
              )}
              id={data.refId.toString()}
            />
            <label
              htmlFor={data.refId.toString()}
              className="absolute flex items-center justify-center w-4 h-4 text-white pointer-events-none"
            >
              {isSelected && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </label>
          </>
        ) : (
          <span className="px-2 py-1 text-xs font-medium rounded bg-primaryTeal-300">
            PRIMARY
          </span>
        )}
      </div>
    </div>
  );
};
