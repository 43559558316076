import { mergeClassNames } from '../common/utils/tailwind';
import React, { FC, useId } from 'react';

interface ButtonProps {
  title: string;
  type?: 'outline' | 'filled' | 'disabled';
  onClick?: () => void;
  className?: string;
}

const Button: FC<ButtonProps> = ({
  title,
  type = 'outline',
  onClick,
  className,
}) => {
  const id = useId();

  if (type === 'outline') {
    return (
      <button
        id={id}
        className={mergeClassNames(
          'w-[270px] border-2 border-primary-400 rounded-md py-2 cursor-pointer hover:border-primary-300',
          className
        )}
        onClick={onClick}
      >
        <div className="font-semibold text-primary-400">{title}</div>
      </button>
    );
  }

  if (type === 'disabled') {
    return (
      <button
        id={id}
        className={mergeClassNames(
          'w-[270px] bg-neutral-200  rounded-md py-2 cursor-default',
          className
        )}
        onClick={onClick}
      >
        <div className="font-semibold text-neutral-500">{title}</div>
      </button>
    );
  }

  return (
    <button
      id={id}
      className={mergeClassNames(
        'w-[270px] bg-primary-400 rounded-md py-2 cursor-pointer hover:bg-primary-300',
        className
      )}
      onClick={onClick}
    >
      <div className="font-semibold text-white">{title}</div>
    </button>
  );
};

export default Button;
