import { useDispatch } from 'react-redux';
import feedImage from '@common/assets/images/mygrdc/feed.svg';
import { useEffect } from 'react';
import { incrementStep } from '@common/store/registrationSlice';
import { useNavigate } from 'react-router-dom';
import RegistrationTitle from './registrationTitle';
import { useIsAuthenticated } from '@azure/msal-react';
import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile';

const AccountCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const { data: user } = useGetUserProfileQuery();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setTimeout(() => {
      dispatch(incrementStep());
    }, 3000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [navigate, dispatch, user]);

  if (!user || !isAuthenticated) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center flex-1 gap-8 mt-10">
      <RegistrationTitle title="Creating your account..." />
      <img src={feedImage} alt="Feed" width={200} className="animate-pulse" />
    </div>
  );
};

export default AccountCreation;
