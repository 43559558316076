import clsx from 'clsx';
import { ReactNode } from 'react';

const RegistrationContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'max-w-[800px]  lg:w-[800px] mx-auto flex-1 px-4 lg:p-0',
        className
      )}
    >
      {children}
    </div>
  );
};

export default RegistrationContainer;
