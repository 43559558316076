interface ProgressBarProps {
  totalSteps: number;
  currentStep: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalSteps,
  currentStep,
}) => {
  const percentage = (currentStep / totalSteps) * 100;
  return (
    <div className="flex items-center w-full">
      <div className="w-full h-3 overflow-hidden rounded-full bg-neutral-200">
        <div
          className="h-full duration-300 rounded-full bg-primary-400 transition-width"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="w-8 ml-2 text-xs font-medium text-right">{`${currentStep} / ${totalSteps}`}</span>
    </div>
  );
};

export default ProgressBar;
