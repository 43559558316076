import React, { ReactNode } from 'react';
import Navbar from './navbar';
import clsx from 'clsx';

const Container = ({
  navbar = <Navbar />,
  className,
  children,
}: {
  navbar?: ReactNode;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={clsx('min-h-[100vh] flex flex-col', className)}>
      {navbar}
      {children}
    </div>
  );
};

export default Container;
