import { createSlice } from '@reduxjs/toolkit';

export interface IAuthenticationState {
    accessToken: string | null; // Change to `string | null` as per your interface
    idToken: string | null;
    refreshToken: string | null;
}

export const initialState: IAuthenticationState = {
    accessToken: null, // Initialize to null
    idToken: null,
    refreshToken: null,
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            state.accessToken = action.payload.token;
            state.idToken = action.payload.tokenExpires;
            state.refreshToken = action.payload.objectId;
        },
        removeToken: (state) => {
            state.accessToken = null;
            state.idToken = null;
            state.refreshToken = null;
        },
    },
});

export const { setToken, removeToken } = authenticationSlice.actions;
export default authenticationSlice.reducer;

// Selectors
export const selectAccessToken = (state: { authentication: IAuthenticationState }) => state.authentication.accessToken;
export const selectIdToken = (state: { authentication: IAuthenticationState }) => state.authentication.idToken;
export const selectRefreshToken = (state: { authentication: IAuthenticationState }) =>
    state.authentication.refreshToken;
