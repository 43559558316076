import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPersona,
  setPersona,
  incrementStep,
} from '@common/store/registrationSlice';
import RegistrationTitle from './registrationTitle';
import RegistrationSubtitle from './registrationSubtitle';
import Spinner from '@components/spinner';

import Footer from './footer';
import Button from '@components/button';
import CustomModal from '@components/customModal';
import { PostcodeDetailsInfo } from '@common/services/api/profileServices/_profileService';
import { useGetPostCodesQuery } from '@common/services/api/profileServices/postCode';

import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile'; // Importing to get CRM data

const MIN_POSTCODE_LENGTH = 4; // Define the minimum length of postcode input

const PostCode = () => {
  const dispatch = useDispatch();
  const persona = useSelector(getPersona);
  const { data: userProfile } = useGetUserProfileQuery();

  const [searchTerm, setSearchTerm] = useState<string>(''); // Initialize as an empty string
  const [selectedPostCode, setSelectedPostCode] = useState<
    string | undefined
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  // Pre-fill data from Redux store (if already selected) or CRM (if first load)
  useEffect(() => {
    if (!hasUserInteracted) {
      if (persona?.postCode?.refId) {
        // Restore previously selected value from Redux store
        const fullText = `${persona.postCode.refText} - ${
          persona.primaryRegion?.refText || 'N/A'
        } - ${persona.primarySubregion?.refText || 'N/A'}`;
        setSearchTerm(fullText);
        setSelectedPostCode(persona.postCode.refId);
      } else if (userProfile?.postCode) {
        const firstFourPostcodeDigits = userProfile.postCode.substring(0, 4);
        setSearchTerm(firstFourPostcodeDigits);
        setSelectedPostCode(userProfile.postCode);
      }
    }
  }, [userProfile, persona, hasUserInteracted, dispatch]);

  // Fetch postcodes only when searchTerm is 4 or more characters long
  const beginsWith =
    searchTerm.length >= MIN_POSTCODE_LENGTH ? searchTerm : null; // Use null instead of undefined
  const { data: postCodes, isFetching } = useGetPostCodesQuery(beginsWith, {
    skip: searchTerm.length < MIN_POSTCODE_LENGTH, // Skip fetching if input is less than 4 characters
  });

  const handleSelect = (selectedItem: PostcodeDetailsInfo) => {
    const regionText = selectedItem.defaultRegion?.refText || 'N/A';
    const subregionText = selectedItem.defaultSubregion?.refText || 'N/A';
    const fullText = `${selectedItem.refText} - ${regionText} - ${subregionText}`;

    setSearchTerm(fullText || '');

    setSelectedPostCode(selectedItem.refId);

    dispatch(
      setPersona({
        postCode: {
          refId: selectedItem.refId,
          refText: selectedItem.refText,
        },
        primaryRegion: {
          refId: selectedItem.defaultRegion?.refId,
          refText: selectedItem.defaultRegion?.refText,
        },
        primarySubregion: {
          refId: selectedItem.defaultSubregion?.refId,
          refText: selectedItem.defaultSubregion?.refText,
        },
      })
    );
  };

  const handleClear = () => {
    setSearchTerm(''); // Clear the search input
    setSelectedPostCode(undefined); // Clear the selected postcode
    setHasUserInteracted(true);

    dispatch(
      setPersona({
        postCode: undefined,
        primaryRegion: undefined,
        primarySubregion: undefined,
      })
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the search term as the user types
    setHasUserInteracted(true);
  };

  const handleNext = () => {
    setIsModalOpen(true); // Open the modal when Next is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleConfirmNext = () => {
    setIsModalOpen(false);
    dispatch(incrementStep()); // Proceed to the next step
  };

  const displayActiveNextBtn = persona.postCode?.refId;

  return (
    <div className="mt-6">
      <RegistrationTitle title="Tell us your postcode" />
      <RegistrationSubtitle subtitle="This will help us know your region. Then, we can better personalise your MyGRDC experience. You can always change this later." />

      <div className="flex justify-start mt-12">
        <div className="w-full max-w-md">
          {/* Postcode search input */}
          <div className="flex items-center p-2 border rounded-md border-neutral-700">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange} // Update search term as user types
              placeholder="Search for a postcode"
              className="flex-grow ml-2 outline-none text-neutral-600 font-regular placeholder-neutral-600"
            />
            {searchTerm && (
              <button onClick={handleClear} className="ml-2 text-neutral-600">
                Clear
              </button>
            )}
          </div>

          {/* Show the dropdown if postcodes are available */}
          {postCodes && searchTerm.length >= MIN_POSTCODE_LENGTH && (
            <ul className="mt-2 border-neutral-300 rounded max-h-60 overflow-y-auto">
              {postCodes.map((item) => (
                <li
                  key={item.refId}
                  onClick={() => handleSelect(item)} // Handle item click
                  className={`p-2 cursor-pointer hover:bg-gray-200 ${
                    selectedPostCode === item.refId ? 'bg-gray-300' : ''
                  }`}
                >
                  {item.refText} - {item.defaultRegion?.refText || 'N/A'} -{' '}
                  {item.defaultSubregion?.refText || 'N/A'}
                </li>
              ))}
              {postCodes.length === 0 && !selectedPostCode && (
                <li className="p-2">No matching postcodes found</li>
              )}
            </ul>
          )}

          {/* Show the spinner if loading */}
          {isFetching && <Spinner />}
        </div>
      </div>

      <Footer>
        <div className="flex justify-end h-full">
          <div className="flex items-center gap-5">
            {displayActiveNextBtn ? (
              <Button
                title="Next"
                type="filled"
                onClick={handleNext} // Open the modal on Next click
              />
            ) : (
              <Button title="Next" type="disabled" />
            )}
          </div>
        </div>
      </Footer>

      {/* Render the modal */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Region confirmed"
      >
        <>
          <div className="py-1 mb-1">
            <div className="flex items-center justify-between pb-2 mb-3 border-b">
              <span className="text-lg font-semibold leading-6 text-left text-neutral-800">
                Your region
              </span>
              <span className="font-regular text-right text-neutral-800">
                {persona.primaryRegion?.refText || 'N/A'}
              </span>
            </div>
            <div className="flex items-center justify-between pb-4 border-b">
              <span className="text-lg font-semibold leading-6 text-left text-neutral-800">
                Your sub-region
              </span>
              <span className="font-regular text-neutral-800">
                {persona.primarySubregion?.refText || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row justify-end gap-4 mt-8">
            <button
              onClick={handleCloseModal}
              className="px-6 py-2 border-2 border-primary-400 text-primary-400 font-semibold rounded-lg hover:bg-primary-50"
            >
              Back
            </button>
            <button
              onClick={handleConfirmNext}
              className="px-6 py-2 bg-primary-400 text-white font-semibold rounded-lg hover:bg-primary-500"
            >
              Next
            </button>
          </div>
        </>
      </CustomModal>
    </div>
  );
};

export default PostCode;
