import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './pages/notFound/notFound';
import Welcome from './pages/welcome/welcome';
import Registration from './pages/registration/registration';
import ProtectedRoute from './components/protectedRoute';
import NoPermission from './pages/noPermission/noPermission';
import Dashboard from './pages/dashboard/dashboard';
import Search from './pages/dashboard/search';
import AllLatestReleasePage from './pages/dashboard/allLatestReleasesPage';
import '../src/App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/registration" element={<Registration />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/search" element={<Search />} />
              <Route path="/saved" element={<Search />} />
              <Route
                path="/allLatestReleases"
                element={<AllLatestReleasePage />}
              />
            </Route>
            <Route path="/no-permission" element={<NoPermission />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
